import { DateTime } from 'luxon';

import './content.css';

import { GuessTheGame } from '../../icons/GuessTheGame';
import { SearchInput } from '../../components/searchInput/SearchInput';
import { IconButton } from '../../components/iconButton/IconButton';
import { Info } from '../../icons/Info';
import { Calendar } from '../../icons/Calendar';
import { Stats } from '../../icons/Stats';
import { useContext, useMemo, useState } from 'react';
import { GameContext } from '../../contexts/GameContext';
import { LandingPage } from '../landingPage/LandingPage';
import { ThemeContext } from '../../contexts/ThemeContext';
import { Theme } from '../../types/Theme';
import { Game } from '../../types/Game';
import { Guesses } from '../guesses/Guesses';
import { WinScreen } from '../winScreen/WinScreen';
import { GuessCount } from './components/GuessCount';
import { LossScreen } from '../lossScreen/LossScreen';
import { Modal } from '../../components/modal/Modal';
import { HowToPlayModalContent } from './components/HowToPlayModalContent';
import { StatsModalContent } from './components/StatsModalContent';
import { HistoryModalContent } from './components/HistoryModalContent';
import { getDate } from '../../helpers/getDate';

export const Content = () => {
    const { games, gameState, addGuess, streak, maxStreak, history } = useContext(GameContext);
    const { theme } = useContext(ThemeContext);

    const [isHowToPlayModalOpen, setIsHowToPlayModalOpen] = useState(false);
    const [isStatModalOpen, setIsStatModalOpen] = useState(false);
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

    const iconColor = useMemo(() => {
        return theme === Theme.Dark ? '#4173ff' : '#69B3E7'
    }, [theme]);

    const handleGameSelect = (game: Game) => {
        addGuess(game);
    }

    const handleInfoClick = () => {
        setIsHowToPlayModalOpen(true);
    };

    const handleStatClick = () => {
        setIsStatModalOpen(true);
    };

    const handleHistoryClick = () => {
        setIsHistoryModalOpen(true);
    };

    const handleHowToPlayModalClose = () => {
        setIsHowToPlayModalOpen(false);
    }

    const handleStatModalClose = () => {
        setIsStatModalOpen(false);
    }

    const handleHistoryModalClose = () => {
        setIsHistoryModalOpen(false);
    }

    const handleLeaveFeedback = () => {
        window.location.href = 'https://www.ggrecon.com/contact-us/'
    }

    const content = useMemo(() => {
        if (gameState.guesses.length === 0) return <LandingPage />
        if (gameState.guesses.some(guess => guess.isCorrect)) return <WinScreen onCalendarClick={handleHistoryClick} />;
        if (gameState.guesses.length === 10) return <LossScreen onCalendarClick={handleHistoryClick} />;
        return <Guesses guesses={gameState.guesses} actual={gameState.answer} />
    }, [gameState]);

    return (
        <div className={`${theme} content-background flex-1 pt-[89px] sm:pt-[118px] md:pt-6 sm:pb-6 flex flex-col items-center w-screen`}>
            <div className="w-screen max-w-[480px] box-border bg-white dark:bg-secondary-900 sm:rounded-xl shadow-2md px-3 py-10 flex flex-col items-center flex-1 sm:flex-initial">
                <div className="w-full max-w-[336px] flex flex-col items-center">
                    <div>
                        <GuessTheGame theme={theme} />
                    </div>
                    {gameState.date && gameState.date !== getDate() && (
                        <div className="font-square text-secondary-700 text-xs mt-1">
                            {DateTime.fromISO(gameState.date).toLocaleString(DateTime.DATE_MED)}
                        </div>
                    )}
                    <div className="w-full flex justify-between items-center mt-5 mb-2">
                        <GuessCount gameState={gameState} />
                        <div className="flex">
                            <div className="mr-3">
                                <IconButton onClick={handleInfoClick}>
                                    <Info color={iconColor} />
                                </IconButton>
                            </div>
                            <div className="mr-3">
                                <IconButton onClick={handleHistoryClick}>
                                    <Calendar color={iconColor}  />
                                </IconButton>
                            </div>
                            <div>
                                <IconButton onClick={handleStatClick}>
                                    <Stats color={iconColor}  />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div className="w-full pb-6">
                        <SearchInput options={games} onSelect={handleGameSelect} />
                    </div>
                    {content}
                </div>
            </div>
            {isHowToPlayModalOpen && (
                <Modal
                    onClose={handleHowToPlayModalClose}
                    title={<div className="font-square text-base text-white">HOW TO PLAY</div>}
                    action={{ label: 'Leave Feedback', onClick: handleLeaveFeedback }}
                >
                    <HowToPlayModalContent />
                </Modal>
            )}
            {isStatModalOpen && (
                <Modal
                    onClose={handleStatModalClose}
                    title={<div className="font-square text-base text-white">YOUR STATS</div>}
                >
                    <StatsModalContent streak={streak} maxStreak={maxStreak} history={history} />
                </Modal>
            )}
            {isHistoryModalOpen && (
                <Modal
                    onClose={handleHistoryModalClose}
                    title={<div className="font-square text-base text-white">PREVIOUS GAMES</div>}
                >
                    <HistoryModalContent />
                </Modal>
            )}
        </div>
    )
};
