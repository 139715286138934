import { useCallback, useEffect, useRef, useState } from "react";
import { useClickOutsideListener } from "../../../hooks/useClickOutsideListener";
import { FacebookIcon, FacebookShareButton, RedditIcon, RedditShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from "react-share";
import { Copy } from "../../../icons/Copy";

interface Props {
    url: string
    message: string
    onClose: () => void
}

export const ShareModal = ({ onClose, message, url }: Props) => {
    const [marginBottom, setMarginBottom] = useState(-200);
    const [copyTimeout, setCopyTimeout] = useState<NodeJS.Timeout | null>(null);
    const handleClose = useCallback(() => {
        setMarginBottom(-200);
        setTimeout(() => {
            onClose();
        }, 150);
    }, [onClose]);

    const ref = useRef<HTMLDivElement>(null)
    useClickOutsideListener(ref, handleClose);

    useEffect(() => {
        setMarginBottom(0);
    }, []);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(`${message} - ${url}`);
        showCopyMessage();
    }

    const showCopyMessage = () => {
        if (copyTimeout) clearTimeout(copyTimeout);

        const timeout = setTimeout(() => {
            setCopyTimeout(null);
        }, 1500);

        setCopyTimeout(timeout);
    }

    return (
        <div className="overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 bottom-0 z-[1300] flex justify-center items-end w-full max-w-full max-h-full bg-black bg-opacity-80">
            <div
                ref={ref}
                style={{
                    marginBottom,
                    transition: 'margin cubic-bezier(0.4, 0, 0.2, 1) 150ms'
                }}
                className="bg-secondary-500 relative w-full max-w-[480px] rounded-t-md box-border overflow-y-hidden flex flex-col p-3 transition-transform"
            >
                <div className="text-center font-square mb-2 text-lg">
                    SHARE
                </div>
                <div className="grid grid-cols-4 gap-3">
                    <div className="flex items-center justify-center flex-col">
                        <FacebookShareButton
                            url={url}
                            title={message}
                        >
                            <FacebookIcon size={64} round />
                        </FacebookShareButton>
                        <div className="font-square text-xs mt-2">Facebook</div>
                    </div>
                    <div className="flex items-center justify-center flex-col">
                        <WhatsappShareButton
                            url={url}
                            title={message}
                        >
                            <WhatsappIcon size={64} round/>
                        </WhatsappShareButton>
                        <div className="font-square text-xs mt-2">Whatsapp</div>
                    </div>
                    <div className="flex items-center justify-center flex-col">
                        <TwitterShareButton
                            url={url}
                            title={message}
                        >
                            <XIcon size={64} round/>
                        </TwitterShareButton>
                        <div className="font-square text-xs mt-2">X</div>
                    </div>
                    <div className="flex items-center justify-center flex-col">
                        <RedditShareButton
                            url={url}
                            title={message}
                        >
                            <RedditIcon size={64} round/>
                        </RedditShareButton>
                        <div className="font-square text-xs mt-2">Reddit</div>
                    </div>
                    <div className="flex items-center justify-center flex-col">
                        <button
                            className="flex items-center justify-center h-16 w-16 rounded-full bg-secondary-700"
                            onClick={handleCopyClick}
                        >
                            <Copy size={32} />
                        </button>
                        <div className="font-square text-xs mt-2">Copy</div>
                    </div>
                </div>
            </div>
            <div className={`fixed bottom-3 w-full flex items-center justify-center z-[1400] transition-all ${copyTimeout ? 'scale-100' : 'scale-0'}`}>
                <div className="bg-secondary-700 px-2 py-1 rounded font-square opacity-80">
                    Copied!
                </div>
            </div>
        </div> 
    )
};