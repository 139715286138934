import { Theme } from "../types/Theme";

export const setThemeCookie = (value: Theme) => {
    const isBrowser = typeof window !== 'undefined';
    if (!isBrowser) return;

    const expires = new Date(
      Date.now() + 7 * 864e5
    ).toUTCString();
  
    document.cookie =
      'theme' +
      '=' +
      encodeURIComponent(value) +
      '; expires=' +
      expires +
      '; path=/';
};
