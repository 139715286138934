import { GuessDisplay } from "../../components/guessDisplay/GuessDisplay";
import { YearDisplay } from "../../components/yearDisplay/YearDisplay";
import { Game } from "../../types/Game";
import { Category } from "./components/Catergory";

import fortnite from '../../assets/images/fortnite.png';
import { PlatformHelper } from "../../components/platformHelper/PlatformHelper";

const EXAMPLE_GUESS: Game = {
    id: '1230',
    name: 'Fortnite',
    image: fortnite,
    releaseYear: 2017,
    genre: 'Battle Royale',
    publisher: 'Epic Games',
    developer: 'Epic Games',
    platform: ['PlayStation 4', 'PlayStation 5', 'Xbox One', 'Xbox Series X|S', 'Nintendo Switch', 'PC', 'Mobile'],
};

const EXAMPLE_ANSWER: Game = {
    id: '1230',
    name: 'Not Fortnite',
    releaseYear: 2017,
    genre: 'Third Person Shooter',
    publisher: 'Snowstorm',
    developer: '',
    platform: ['PlayStation 4', 'Xbox'],
    image: null,
};

export const LandingPage = () => {
    return (
        <div>
            <div className="font-square text-center text-black dark:text-white mb-1 text-sm">Welcome To 'Guess the Game'</div>
            <div className="font-opensans text-center text-black dark:text-white text-xs mb-7">
                The video game-themed word game. Every day you need to guess the correct game in as little guesses as possible. You have 10 chances to guess the mystery game name.
            </div>
            <div className="border-solid border-2 border-secondary-500 rounded-lg flex flex-col items-center p-3 dark:bg-black dark:border-black mb-7">
                <div style={{ marginTop: -26 }} className="font-square text-white dark:text-black bg-black dark:bg-white px-3 py-2 text-center shadow-2md text-xxs rounded-sm">EXAMPLE</div>
                <div className="w-full">
                    <GuessDisplay guess={EXAMPLE_GUESS} actual={EXAMPLE_ANSWER} />
                    <div className="mt-2">
                        <PlatformHelper />
                    </div>  
                </div>
            </div>
            <div className="font-square text-center text-black dark:text-white mb-2 text-sm">How to play Guess the Game</div>
            <div className="grid grid-cols-3 gap-x-3 mb-6">
                <div>
                    <div className="font-square text-sm text-black dark:text-white text-center mb-2">WRONG</div>
                    <YearDisplay year={2007} targetYear={1990} showHelpers={false} />
                </div>
                <div>
                    <div className="font-square text-sm text-black dark:text-white text-center mb-2">CLOSE</div>
                    <YearDisplay year={1989} targetYear={1990} showHelpers={false} />
                </div>
                <div>
                    <div className="font-square text-sm text-black dark:text-white mb-2">CORRECT</div>
                    <YearDisplay year={1990} targetYear={1990} showHelpers={false} />
                </div>
            </div>
            <div className="font-square text-center text-black dark:text-white mb-1 text-sm">The Categories</div>
            <div className="font-opensans text-center text-black dark:text-white text-xs mb-4">
                The game consists of 5 different categories that provide clues for the game that is chosen to be the answer for that day. Below is a list of the 5 categories and an explanation of what each means, as well as when they will turn amber to indicate that you’re close to the correct answer.
            </div>
            <div className="mb-2">
                <Category
                    count="01"
                    title="RELEASE YEAR"
                    description="The year the game was released, not when it was re-released. An amber indicator will appear if your guess is within 2 years of the answer’s released year."
                />
            </div>
            <div className="mb-2">
                <Category count="02" title="GENRE" description="The specific genre of the game, such as FPS (First person shooter) or RPG (Role playing game)." />
            </div>
            <div className="mb-2">
                <Category count="03" title="PUBLISHER" description="This is specific to the main publisher of that specific game, however in some cases, this could be the same as the game developer. With some games, they have multiple publishers, we show the North American publisher and/or the publisher at release." />
            </div>
            <div className="mb-2">
                <Category count="04" title="DEVELOPER" description="This relates to the developer who created the game, but in some cases, this could be the same as the game publisher." />
            </div>
            <div>
                <Category
                    count="05"
                    title="PLATFORM"
                    description="The platform(s) that the game is playable on. Most of the time showing multiple platforms. This will go amber if some of the platforms are close or if some of the platforms are correct, click the 'How do platform indicators work' to learn more about the platforms."
                />
            </div>
        </div>
    )
};
