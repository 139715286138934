interface Props {
    count: string
    title: string
    description?: string
}

export const Category = ({ count, title, description = '' }: Props) => (
    <div className="bg-secondary-500 dark:bg-secondary-1000 rounded-lg flex flex-col items-center p-2">
        <div className="font-square text-secondary-700 text-xxs mb-1">{count}</div>
        <div className="font-inter text-xs text-black dark:text-white font-bold">{title}</div>
        {description && (
            <div className="font-opensans text-xs mt-1 text-center dark:text-white">
                {description}
            </div>
        )}
    </div>
)