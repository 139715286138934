import { useMemo } from 'react';
import './helperTooltip.css';

interface Props {
    text: string
    tooltipId: string
    backgroundColor: string
    textColor: string
    position?: 'center' | 'left' | 'right'
}

export const HelperTooltip = ({ text, tooltipId, textColor, backgroundColor, position = 'center' }: Props) => {
    const tooltipPositionClasses = useMemo(() => {
        switch (position) {
            case 'center':
                return 'left-0 translate-x-[-50%] ml-auto mr-auto';
            case 'left':
                return 'right-[-20px] tooltip-left';
            case 'right':
                return 'left-[-20px] tooltip-right';
            default:
                return '';
        }
    }, [position]);
    return (
        <div className="group relative flex">
            <div
                className={`w-3 h-3 rounded-full ${backgroundColor} flex items-center justify-center text-[10px] cursor-pointer bg-opacity-50 helper-tooltip-button font-bold`}
                data-tooltip-target={tooltipId}
            >
                <span className={`mt-px ${textColor}`}>?</span>
            </div>
            <div className={`tooltip z-50 absolute ${tooltipPositionClasses} top-4 scale-0 transition-all rounded dark:bg-secondary-500 bg-secondary-800 p-2 text-xs dark:text-black text-white group-hover:scale-100 font-interbold w-64`}>
                {text}
            </div>
        </div>
    );
};
