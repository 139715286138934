import { ReactNode } from "react"

interface Props {
    onClick: () => void
    children: ReactNode
}

export const IconButton = ({ children, onClick }: Props) => {
    return (
        <button onClick={onClick}>
            {children}
        </button>
    )
};
