import { Streak } from "../../../icons/Streak";

interface Props {
    streakCount: number
}

export const StreakText = ({ streakCount }: Props) => (
    <div className="flex items-center">
        <Streak />
        <div className="font-square text-white ml-1.5 text-base uppercase">{streakCount} day streak</div>
    </div>
);
