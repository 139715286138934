import { useMemo } from "react";
import { HelperTooltip } from "../helperTooltip/HelperTooltip";

interface Props {
    guess: string
    actual: string
    bordered?: boolean
    showHelpers?: boolean
}

enum Status {
    Incorrect,
    Correct,
}

export const PublisherDisplay = ({ guess, actual, bordered = false, showHelpers = true }: Props) => {
    const status = useMemo(() => {
        if (guess === actual) return Status.Correct;
        return Status.Incorrect;
    }, [guess, actual]);

    const backgroundClass = useMemo(() => {
        switch(status) {
            case Status.Correct:
                return 'bg-green-500 dark:bg-green-300';
            default:
                return 'bg-secondary-500 dark:bg-secondary-1000';
        }
    }, [status]);


    const textClass = useMemo(() => status === Status.Incorrect ? 'text-black dark:text-white' : 'text-white dark:text-black', [status]);
    const borderClass = bordered ? 'border-2 border-black border-solid' : '';

    return (
        <div className={`rounded ${backgroundClass} flex flex-col justify-center items-center py-1 ${borderClass}`}>
            <div className={`font-interbold text-xs ${textClass} flex`}>
                <div className="opacity-50">Publisher</div>
                {showHelpers && (
                    <div className="ml-0.5 mt-px">
                        <HelperTooltip
                            textColor={backgroundClass.replaceAll('bg-', 'text-')}
                            backgroundColor={textClass.replaceAll('text-', 'bg-')}
                            tooltipId="publisher-helper"
                            text="This is specific to the main publisher of that specific game, however in some cases, this could be the same as the game developer. With some games, they have multiple publishers, we show the North American publisher and/or the publisher at release."
                        />
                    </div>
                )}
            </div>
            <div className={`font-interbold text-lg ${textClass} flex items-center`}>
                {guess}
            </div>
        </div>
    )
};
