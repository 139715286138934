import { useMemo } from "react"
import { ResultSummary } from "../../../types/Result"
import { isCorrectAnswer } from "../../../helpers/isCorrentAnswer"

interface Props {
    streak: number
    maxStreak: number
    history: Record<string, ResultSummary>
}

const VALID_GUESS_COUNTS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const StatsModalContent = ({ streak, maxStreak, history }: Props) => {
    const resultSummary = useMemo(() => {
        return Object.values(history).reduce<Record<number, number>>((output, entry) => {
            if (!isCorrectAnswer(entry)) return output;
            if (entry.guesses >=0 && entry.guesses <= 10) {
                return {
                    ...output,
                    [entry.guesses]: output[entry.guesses] ? output[entry.guesses] + 1 : 1,
                }
            }
            return output;
        }, {});
    }, [history]);

    const maxValue = useMemo(() => {
        return Object.values(resultSummary).reduce((output, entry) => {
            return Math.max(entry, output);
        }, 1);
    }, [resultSummary])

    return (
        <div className="grid grid-cols-2 gap-3">
            <div className="rounded col-span-1 bg-white dark:bg-black flex flex-col font-square p-3">
                <div className="text-center text-secondary-700 dark:text-secondary-750 text-base mb-3">CURRENT STREAK</div>
                <div className="text-center text-3xl dark:text-white">{streak}</div>
            </div>
            <div className="rounded col-span-1 bg-white dark:bg-black flex flex-col font-square p-3">
                <div className="text-center text-secondary-700 dark:text-secondary-750 text-base mb-3">LONGEST STREAK</div>
                <div className="text-center text-3xl dark:text-white">{maxStreak}</div>
            </div>
            <div className="rounded col-span-2 bg-white dark:bg-black p-3 pt-10 flex justify-between">
                {VALID_GUESS_COUNTS.map((count) => {
                    const value = resultSummary[count] || 0;
                    const percentOfMax = Math.round(value * 100 / maxValue);

                    return (
                        <div className="flex flex-col items-center" key={count}>
                            <div className="h-14 flex flex-col justify-end">
                                <div
                                    style={{ height: `${percentOfMax}%`}}
                                    className="w-4 min-h-[1px] bg-green-500 dark:bg-green-300 mb-1.5"
                                />
                            </div>
                            <div className="font-square text-sm dark:text-white">{count}</div>
                            <div className="font-square text-xs text-secondary-700 dark:text-secondary-750">{value}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}