import { useRef, useState } from "react"
import { useClickOutsideListener } from "../../../hooks/useClickOutsideListener";

export const TopHeader = () => {
    const moreDropRef = useRef<HTMLDivElement>(null);
    const [showMore, setShowMore] = useState(false);

    const closeMoreMenu = () => {
        if (showMore) {
            setShowMore(false);
        }
    }
    useClickOutsideListener(moreDropRef, closeMoreMenu)

    const handleMoreClick = () => setShowMore(true);
    
    return (
        <nav className="navbar">
            <div className="navbarBox">
            <div ref={moreDropRef} className="moreDrop" style={{ display: showMore ? 'block' : 'none' }}>
            <div className="moreDropTop" onClick={closeMoreMenu}>More</div>

            <div className="moreDropList">
                <a href="https://www.ggrecon.com/diablo/">Diablo</a>
                <a href="https://www.ggrecon.com/zelda/">Zelda</a>
                <a href="https://www.ggrecon.com/fifa/">FIFA</a>
                <a href="https://www.ggrecon.com/star-wars-jedi-survivor/">Star Wars Jedi: Survivor</a>
                <a href="https://www.ggrecon.com/final-fantasy/">Final Fantasy</a>
                <a href="https://www.ggrecon.com/hogwarts-legacy/">Hogwarts Legacy</a>
                <a href="https://www.ggrecon.com/overwatch-2/">Overwatch 2</a>
                <a href="https://www.ggrecon.com/valorant/">VALORANT</a>
                <a href="https://www.ggrecon.com/entertainment/">Entertainment</a>
                <a href="https://www.ggrecon.com/league-of-legends/">LOL</a>
                <a href="https://www.ggrecon.com/rocket-league/">RL</a>
                <a href="https://www.ggrecon.com/destiny/">Destiny</a>
                <a href="https://www.ggrecon.com/counter-strike/">Counter-Strike</a>
                <a href="https://www.ggrecon.com/trending/">Trending</a>
                <a href="https://www.ggrecon.com/pokemon-go/">Pokemon GO</a>
                <a href="https://www.ggrecon.com/the-sims/">The Sims</a>
                <a href="https://www.ggrecon.com/genshin-impact/">Genshin Impact</a>
                <a href="https://www.ggrecon.com/dmz/">DMZ</a>
                <a href="https://www.ggrecon.com/modern-warfare-3/">Modern Warfare 3</a>
            </div>
            </div>
                <div className="navbarList">
                    <a href="https://www.ggrecon.com/call-of-duty/">Call of Duty</a>
                    <a href="https://www.ggrecon.com/fortnite/">Fortnite</a>
                    <a href="https://www.ggrecon.com/apex-legends/">Apex Legends</a>
                    <a href="https://www.ggrecon.com/pokemon/">Pokemon</a>
                    <a href="https://www.ggrecon.com/roblox/">Roblox</a>
                    <a href="https://www.ggrecon.com/starfield/">Starfield</a>
                    <a href="https://www.ggrecon.com/baldurs-gate-3/">Baldur's Gate 3</a>
                    <a href="https://www.ggrecon.com/ea-sports-fc/">EA FC</a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={handleMoreClick}>More</a>
                </div>
            </div>
    </nav>
    )
}