import { ReactNode, useEffect } from "react"
import { Close } from "../../icons/Close"

import './modal.css';

interface Props {
    title: ReactNode
    children: ReactNode
    onClose: () => void
    action?: {
        label: string
        onClick: () => void
    }
}

export const Modal = ({ title, children, onClose, action }: Props) => {
    useEffect(()  => {
        document.body.style.overflow = 'hidden';
        document.body.style.maxHeight = '100vh';

        return () => {
            document.body.style.overflow = 'auto';
            document.body.style.maxHeight = 'initial';
        }
    }, []);

    return (
        <div className="overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 bottom-0 z-[1300] flex justify-center items-center w-full max-w-full max-h-full bg-black bg-opacity-80 p-3">
            <div className="modal-background relative w-full max-w-[336px] max-h-full rounded-lg box-border overflow-y-hidden flex flex-col">
                <div className="flex justify-between items-center p-3">
                    <div className="flex-1">
                        {title}
                    </div>
                    <button className="h-8 w-8 flex items-center justify-center rounded-full bg-white bg-opacity-50" onClick={onClose}>
                        <Close />
                    </button>
                </div>
                <div className="px-3 pb-3 flex-1 overflow-y-auto min-h-[0]">
                    {children}
                </div>
                {action && (
                    <div className="bg-white dark:bg-secondary-1000 w-full p-3 pt-3.5">
                        <button
                            onClick={action.onClick}
                            className="w-full bg-blue-300 dark:bg-blue-700 p-3.5 font-opensans font-bold text-white rounded-lg"
                        >
                            {action.label}
                        </button>
                    </div>
                )}
            </div>
        </div> 
    )
}
