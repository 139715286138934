import { PlatformMap } from "../../../types/PlatformMap";

export const isSameBrand = (platform: string, actual: string[], platformMap: PlatformMap) => {
    return actual.some((actualPlatform) => {
        const guessBrand = platformMap[platform]?.brand;

        if (!guessBrand) return false;

        const actualBrand = platformMap[actualPlatform]?.brand;

        if (!actualBrand) return false;

        return guessBrand === actualBrand;
    });
}
