import { GuessDisplay } from "../../../components/guessDisplay/GuessDisplay";
import { Game } from "../../../types/Game";

import pokemon from '../../../assets/images/pokemon.png';
import farcryprimal from '../../../assets/images/farcryprimal.png';
import tekken from '../../../assets/images/tekken.png';
import untildawn from '../../../assets/images/untildawn.png';
import fortnite from '../../../assets/images/fortnite.png';
import { YearDisplay } from "../../../components/yearDisplay/YearDisplay";
import { Category } from "../../landingPage/components/Catergory";
import { PlatformHelper } from "../../../components/platformHelper/PlatformHelper";

const guess1: Game = {
    id: '123',
    name: 'Pokémon Legends: Arceus',
    releaseYear: 2022,
    genre: 'RPG',
    publisher: 'Nintendo',
    developer: 'Game Freak',
    platform: ['Nintendo Switch'],
    image: pokemon,
}

const guess2: Game = {
    id: '321',
    name: 'Tekken',
    releaseYear: 1994,
    genre: 'Figting',
    publisher: 'Bandai Namco',
    developer: 'Bandai Namco',
    platform: ['Arcade', 'PlayStation'],
    image: tekken,
}

const guess3: Game = {
    id: '111',
    name: 'Far Cry Primal',
    releaseYear: 2016,
    genre: 'Action Adventure',
    publisher: 'Ubisoft',
    developer: 'Ubisoft',
    platform: ['PSP', 'Xbox One', 'PC'],
    image: farcryprimal,
}


const answer: Game = {
    id: '1234',
    name: 'Until Dawn',
    releaseYear: 2015,
    genre: 'Horror',
    publisher: 'Sony',
    developer: 'Supermassive Games',
    platform: ['PlayStation 4', 'PlayStation 5', 'PC'],
    image: untildawn,
}

const EXAMPLE_GUESS: Game = {
    id: '1230',
    name: 'Fortnite',
    image: fortnite,
    releaseYear: 2017,
    genre: 'Battle Royale',
    publisher: 'Epic Games',
    developer: 'Epic Games',
    platform: ['PlayStation 4', 'PlayStation 5', 'Xbox One', 'Xbox Series X|S', 'Nintendo Switch', 'PC', 'Mobile'],
};

const EXAMPLE_ANSWER: Game = {
    id: '1230',
    name: 'Not Fortnite',
    releaseYear: 2017,
    genre: 'Third Person Shooter',
    publisher: 'Snowstorm',
    developer: '',
    platform: ['PlayStation 4', 'Xbox'],
    image: null,
};

export const HowToPlayModalContent = () => {
    return (
        <div>
            <div className="text-xs text-white mb-3">
                Each day there is a new video game that you need to guess in as few attempts as possible.
            </div>
            <div className="bg-white dark:bg-black rounded p-3 mb-3">
                <div className="font-square text-sm mb-1 flex justify-between">
                    <span className="dark:text-white">Guess 01:</span>
                    <span className="text-secondary-700">0 Correct 0 Close</span>
                </div>
                <div className="text-xs dark:text-white mb-3">In this example, none of the categories match, or are close, to the answer.</div>
                <div>
                    <GuessDisplay guess={guess1} actual={answer} showHelpers={false} />
                </div>
            </div>
            <div className="bg-white dark:bg-black rounded p-3 mb-3">
                <div className="font-square text-sm mb-1 flex justify-between">
                    <span className="dark:text-white">Guess 02:</span>
                    <span className="text-green-500">0 Correct</span>
                    <span className="text-yellow-500 ml-2">1 Close</span>
                </div>
                <div className="text-xs dark:text-white mb-3">
                    In this example, none of the first four categories are correct for the guess made, with the platform category as amber as one of the platforms is close (It's on another PlayStation model in this example).
                </div>
                <div>
                    <GuessDisplay guess={guess2} actual={answer} showHelpers={false} />
                </div>
            </div>
            <div className="bg-white dark:bg-black rounded p-3 mb-3">
                <div className="font-square text-sm mb-1 flex justify-between">
                    <span className="dark:text-white">Guess 03:</span>
                    <span className="text-green-500">1 Correct</span>
                    <span className="text-yellow-500 ml-2">2 Close</span>
                </div>
                <div className="text-xs dark:text-white mb-3">
                    In this example, the release year is amber as it is close to the actual release year of the answer. The platforms show PC as green and PSP as amber, showing the answer is on PC, but also on other platforms and the PSP being amber indicates the answer is on another PlayStation platform, but not the PSP itself.
                </div>
                <div>
                    <GuessDisplay guess={guess3} actual={answer} showHelpers={false} />
                </div>
            </div>
            <div className="bg-white dark:bg-black rounded p-3 mb-3">
                <div className="font-square text-sm mb-1 flex justify-between">
                    <span className="dark:text-white">Guess 04:</span>
                    <span className="text-green-500">Guess Correctly!</span>
                </div>
                <div className="text-xs dark:text-white mb-3">
                    This is an example of how it will look when you guess the game correctly, good luck!
                </div>
                <div>
                    <GuessDisplay guess={answer} actual={answer} showHelpers={false} />
                </div>
            </div>

            <div className="bg-white dark:bg-black rounded p-3">
                <div className="text-xs mb-8">Below you will find more examples and information on the indicators and the categories themselves.</div>
                <div className="border-solid border-2 border-secondary-500 rounded-lg flex flex-col items-center p-1 dark:bg-black dark:border-black mb-7 mt-4">
                    <div style={{ marginTop: -26 }} className="font-square text-white dark:text-black bg-black dark:bg-white px-3 py-2 text-center shadow-2md text-xxs rounded-sm">EXAMPLE</div>
                    <div className="w-full">
                        <GuessDisplay guess={EXAMPLE_GUESS} actual={EXAMPLE_ANSWER} showHelpers={false} />
                        <div className="mt-2">
                            <PlatformHelper />
                        </div>  
                    </div>
                </div>
                <div className="font-square text-center text-black dark:text-white mb-2 text-sm">How to play Guess the Game</div>
                <div className="grid grid-cols-3 gap-x-1 mb-6">
                    <div>
                        <div className="font-square text-sm text-black dark:text-white text-center mb-2">WRONG</div>
                        <YearDisplay year={2007} targetYear={1990} showHelpers={false} />
                    </div>
                    <div>
                        <div className="font-square text-sm text-black dark:text-white text-center mb-2">CLOSE</div>
                        <YearDisplay year={1989} targetYear={1990} showHelpers={false} />
                    </div>
                    <div>
                        <div className="font-square text-sm text-black dark:text-white mb-2">CORRECT</div>
                        <YearDisplay year={1990} targetYear={1990} showHelpers={false} />
                    </div>
                </div>
                <div className="font-square text-center text-black dark:text-white mb-1 text-sm">The Categories</div>
                <div className="font-opensans text-center text-black dark:text-white text-xs mb-4">
                    The game consists of 5 different categories that provide clues for the game that is chosen to be the answer for that day. Below is a list of the 5 categories and an explanation of what each means, as well as when they will turn amber to indicate that you’re close to the correct answer.
                </div>
                <div className="mb-2">
                    <Category
                        count="01"
                        title="RELEASE YEAR"
                        description="The year the game was released, not when it was re-released. An amber indicator will appear if your guess is within 2 years of the answer’s released year."
                    />
                </div>
                <div className="mb-2">
                    <Category count="02" title="GENRE" description="The specific genre of the game, such as FPS (First person shooter) or RPG (Role playing game)." />
                </div>
                <div className="mb-2">
                    <Category count="03" title="PUBLISHER" description="This is specific to the main publisher of that specific game, however in some cases, this could be the same as the game developer. With some games, they have multiple publishers, we show the North American publisher and/or the publisher at release." />
                </div>
                <div className="mb-2">
                    <Category count="04" title="DEVELOPER" description="This relates to the developer who created the game, but in some cases, this could be the same as the game publisher." />
                </div>
                <div>
                    <Category
                        count="05"
                        title="PLATFORM"
                        description="The platform(s) that the game is playable on. Most of the time showing multiple platforms. This will go amber if some of the platforms are close or if some of the platforms are correct, click the 'How do platform indicators work' to learn more about the platforms."
                    />
                </div>
            </div>
        </div>
    )
};
