import { ChangeEvent, MouseEvent, useMemo, useState } from 'react';
import { Search } from '../../icons/Search';
import { Game } from '../../types/Game';

import Fuse from 'fuse.js';

interface Props {
    options: Game[]
    onSelect: (game: Game) => void
}

export const SearchInput = ({ options, onSelect }: Props) => {
    const [searchText, setSearchText] = useState('');

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value || '');
    }

    const displayedOptions = useMemo(() => {
        if (searchText.length < 2) return [];
        const fuse = new Fuse(options, { keys: ['name'], threshold: 0.4 })

        const result = fuse.search(searchText)

        return result.map(entry => entry.item)
    }, [options, searchText]);

    const areOptionsOpen = displayedOptions.length > 0;

    const roundedClass = areOptionsOpen ? 'rounded-t-xl' : 'rounded-xl';

    const handleGameClickFactory = (game: Game) => (event: MouseEvent) => {
        setSearchText('');
        onSelect(game)
    };

    return (
        <div className={`${roundedClass} border-solid border-2 border-secondary-500 dark:border-secondary-800 w-full p-3 flex relative dark:bg-black`}>
            <div className="mr-3">
                <Search />
            </div>
            <div className="flex-1">
                <input
                    type="text"
                    className="placeholder:text-secondary-700 font-square text-sm text-black dark:text-white dark:bg-black w-full"
                    value={searchText}
                    onChange={handleTextChange}
                    placeholder="Enter Guess..."
                />
            </div>
            {areOptionsOpen && (
                <div
                    className="absolute bg-white dark:bg-black rounded-b-xl border-solid border-2 border-secondary-500 dark:border-secondary-800 overflow-hidden z-10"
                    style={{
                        left: -2,
                        right: -2,
                        top: 44,
                        maxHeight: 138,
                        overflowY: 'auto',
                    }}
                >
                    {
                        displayedOptions.map((option) => (
                            <button
                                key={option.name}
                                className="w-full text-left font-square text-sm border-solid border-0 border-b-2 last:border-b-0 border-secondary-500 dark:border-secondary-800 text-black dark:text-white p-3 hover:bg-secondary-500"
                                onClick={handleGameClickFactory(option)}
                            >
                                {option.name}
                            </button>
                        ))
                    }
                </div>
            )}
        </div>
    )
};
