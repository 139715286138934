import { GameState } from "../types/GameState";

export const EMPTY_GAME_STATE: GameState = {
    date: '',
    guesses: [],
    answer: {
        id: '',
        name: '',
        releaseYear: 0,
        genre: '',
        publisher: '',
        developer: '',
        platform: [],
        image: null,
    }
};
