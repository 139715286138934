import { GuessDisplay } from "../../components/guessDisplay/GuessDisplay";
import { Game } from "../../types/Game";
import { Guess } from "../../types/Guess";
import { PlatformHelper } from "../platformHelper/PlatformHelper";

interface Props {
    actual: Game
    guesses: Guess[]
}

export const GuessList = ({ actual, guesses }: Props) => {
    return (
        <div className="w-full">
            {[...guesses].reverse().map((guess, index) => (
                <div className="mb-6 w-full" key={guess.game.name}>
                    <GuessDisplay guess={guess.game} actual={actual}/>
                    {index === 0 && (
                        <div className="mt-2">
                            <PlatformHelper />
                        </div>  
                    )}
                </div>
            ))}
        </div>
    )
};
