import { useContext } from 'react';
import { BottomHeader } from './components/BottomHeader';
import { TopHeader } from './components/TopHeader';
import { ThemeContext } from '../../contexts/ThemeContext';
import { Theme } from '../../types/Theme';

export const Header = () => {
    const { theme } = useContext(ThemeContext);
    return (
        <div className={theme === Theme.Light ? 'light' : 'dark'}>
            <TopHeader />
            <BottomHeader />
        </div>
    )
}