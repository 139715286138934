export const splitPlatforms = <T>(arr: Array<T>): Array<Array<T>> => {
    if (arr.length <= 4) return [arr];

    const length = arr.length;

    const isEven = arr.length % 2 === 0;

    const halfLength = isEven ? length / 2 : Math.ceil(length / 2);

    return [arr.slice(0, halfLength), arr.slice(halfLength, length)]
}