import { FirebaseApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { Game } from "../types/Game";
import storagePathToUrl from "./storagePathToUrl";

export const getGames = async (app: FirebaseApp): Promise<any[]> => {
    const db = getFirestore(app);
    const querySnapshot = await getDocs(collection(db, 'games'));

    const result: Game[] = [];
    querySnapshot.forEach((doc) => {
        const game: Game = {
            id: doc.id,
            ...doc.data() as Omit<Game, 'id'>,
            developer: doc.data().developer || '',
        };
        result.push(game);
    });

    const parsedResults: Game[] = await Promise.all(result.map(async (entry: Game): Promise<Game> => ({
        ...entry,
        image: entry.image && entry.image !== '' ? await storagePathToUrl(entry.image) : null,
    })));

    return parsedResults.sort((a, b) => a.name < b.name ? -1 : 1);
}