export const Share = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path
            d="M12.889 16a2.38 2.38 0 0 1-1.731-.7 2.293 2.293 0 0 1-.713-1.7c0-.093.006-.19.02-.29.014-.1.034-.19.061-.27L4.782 9.76c-.231.2-.49.357-.775.47-.285.113-.583.17-.896.17a2.38 2.38 0 0 1-1.731-.7A2.293 2.293 0 0 1 .667 8c0-.667.237-1.233.713-1.7a2.38 2.38 0 0 1 1.731-.7c.313 0 .611.057.896.17.286.113.544.27.775.47l5.744-3.28a1.445 1.445 0 0 1-.061-.27c-.014-.1-.02-.197-.02-.29 0-.667.237-1.233.713-1.7a2.38 2.38 0 0 1 1.731-.7 2.38 2.38 0 0 1 1.732.7c.475.467.712 1.033.712 1.7s-.237 1.233-.713 1.7a2.38 2.38 0 0 1-1.731.7c-.312 0-.611-.057-.896-.17a2.692 2.692 0 0 1-.774-.47L5.474 7.44c.027.08.048.17.061.27a2.156 2.156 0 0 1 0 .58c-.013.1-.034.19-.06.27l5.744 3.28c.23-.2.489-.357.774-.47.285-.113.584-.17.896-.17a2.38 2.38 0 0 1 1.732.7c.475.467.712 1.033.712 1.7s-.237 1.233-.713 1.7a2.38 2.38 0 0 1-1.731.7zm0-12.8a.796.796 0 0 0 .815-.8.796.796 0 0 0-.815-.8.796.796 0 0 0-.815.8.796.796 0 0 0 .815.8zM3.111 8.8a.796.796 0 0 0 .815-.8.796.796 0 0 0-.815-.8.796.796 0 0 0-.815.8.796.796 0 0 0 .815.8zm9.778 5.6a.796.796 0 0 0 .815-.8.796.796 0 0 0-.815-.8.796.796 0 0 0-.815.8.796.796 0 0 0 .815.8z"
            fill="#fff"
        />
    </svg>
);
