import { Game } from "../../types/Game";
import { DeveloperDisplay } from "../developerDisplay/DeveloperDisplay";
import { GenreDisplay } from "../genreDisplay/GenreDisplay";
import { PlatformDisplay } from "../platformDisplay/PlatformDisplay";
import { PublisherDisplay } from "../publisherDisplay/PublisherDisplay";
import { YearDisplay } from "../yearDisplay/YearDisplay";

interface Props {
    guess: Game
    actual: Game
    showHelpers?: boolean
}

export const GuessDisplay = ({ guess, actual, showHelpers = true }: Props) => {
    return (
        <div>
            <div className="flex items-center gap-2 mb-2">
                <div className="rounded-full overflow-hidden bg-secondary-500 h-12 w-12 max-w-12 min-w-12">
                    {guess.image && <img className="object-cover w-full h-full" src={guess.image} alt={guess.name} />}
                </div>
                <div className="font-square uppercase text-sm text-black dark:text-white flex-1">{guess.name}</div>
            </div>
            <div className="grid grid-cols-3 gap-0.5">
                <div>
                    <YearDisplay year={guess.releaseYear} targetYear={actual.releaseYear} showHelpers={showHelpers} />
                </div>
                <div className="col-span-2">
                    <GenreDisplay guess={guess.genre} actual={actual.genre} showHelpers={showHelpers} />
                </div>
                <div className="col-span-3">
                    <PublisherDisplay guess={guess.publisher} actual={actual.publisher} showHelpers={showHelpers} />
                </div>
                <div className="col-span-3">
                    <DeveloperDisplay guess={guess.developer} actual={actual.developer} showHelpers={showHelpers} />
                </div>
                <div className="col-span-3">
                    <PlatformDisplay guess={guess.platform} actual={actual.platform} showHelpers={showHelpers} />
                </div>
            </div>
        </div>
    )
};
