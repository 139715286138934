import { useContext, useState } from "react";
import { ThemeToggle } from "./ThemeToggle";
import { Theme } from "../../../types/Theme";
import { ThemeContext } from "../../../contexts/ThemeContext";

export const BottomHeader = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { theme, setTheme } = useContext(ThemeContext);

    const toggleMenu = () => {
        setMenuOpen((value) => {
            return !value
        });
    }

    const toggleTheme = () => {
        setTheme(theme === Theme.Light ? Theme.Dark : Theme.Light);
    }

    const closeMenu = () => {
        if (menuOpen) {
            setMenuOpen(false);
        }
    }

    return (
        <nav className={`subnav ${menuOpen ? 'menuOpen' : ''}`}>
            <div className="fadeBox" onClick={closeMenu}></div>
            <div className="searchBox" style={{ display: menuOpen ? 'block' : 'none' }}>
                <ThemeToggle />
                <div className="searchBody">
                    <div className="gameScroll">
                        <div className="searchTitle">All Games (27)</div>
                        <div className="searchGameBox">
                            <div className="gamesListRow">
                                <div className="gamesList">
                                    <div><a href="https://www.ggrecon.com/call-of-duty/">Call of Duty</a></div>
                                    <div><a href="https://www.ggrecon.com/fortnite/">Fortnite</a></div>
                                    <div><a href="https://www.ggrecon.com/apex-legends/">Apex Legends</a></div>
                                    <div><a href="https://www.ggrecon.com/pokemon/">Pokemon</a></div>
                                    <div><a href="https://www.ggrecon.com/roblox/">Roblox</a></div>
                                    <div><a href="https://www.ggrecon.com/starfield/">Starfield</a></div>
                                    <div><a href="https://www.ggrecon.com/baldurs-gate-3/">Baldur's Gate 3</a></div>
                                    <div><a href="https://www.ggrecon.com/ea-sports-fc/">EA FC</a></div>
                                    <div><a href="https://www.ggrecon.com/diablo/">Diablo</a></div>
                                    <div><a href="https://www.ggrecon.com/zelda/">Zelda</a></div>
                                    <div><a href="https://www.ggrecon.com/fifa/">FIFA</a></div>
                                    <div><a href="https://www.ggrecon.com/star-wars-jedi-survivor/">Star Wars Jedi: Survivor</a></div>
                                    <div><a href="https://www.ggrecon.com/final-fantasy/">Final Fantasy</a></div>
                                    <div><a href="https://www.ggrecon.com/hogwarts-legacy/">Hogwarts Legacy</a></div>
                                    <div><a href="https://www.ggrecon.com/overwatch-2/">Overwatch 2</a></div>
                                    <div><a href="https://www.ggrecon.com/valorant/">VALORANT</a></div>
                                    <div><a href="https://www.ggrecon.com/entertainment/">Entertainment</a></div>
                                    <div><a href="https://www.ggrecon.com/league-of-legends/">LOL</a></div>
                                    <div><a href="https://www.ggrecon.com/rocket-league/">RL</a></div>
                                    <div><a href="https://www.ggrecon.com/destiny/">Destiny</a></div>
                                    <div><a href="https://www.ggrecon.com/counter-strike/">Counter-Strike</a></div>
                                    <div><a href="https://www.ggrecon.com/trending/">Trending</a></div>
                                    <div><a href="https://www.ggrecon.com/pokemon-go/">Pokemon GO</a></div>
                                    <div><a href="https://www.ggrecon.com/the-sims/">The Sims</a></div>
                                    <div><a href="https://www.ggrecon.com/genshin-impact/">Genshin Impact</a></div>
                                    <div><a href="https://www.ggrecon.com/dmz/">DMZ</a></div>
                                    <div><a href="https://www.ggrecon.com/modern-warfare-3/">Modern Warfare 3</a></div>
                                </div>
                            </div>
                            <div className="gameScrollSoc">
                                <a href="https://www.facebook.com/GGReconEsports" target="_blank" title="facebookLink" rel="noreferrer"></a>
                                <a href="https://twitter.com/ggrecongaming" target="_blank" title="twitterLink" rel="noreferrer"></a>
                                <a href="https://www.instagram.com/ggrecon_/" target="_blank" title="instagramLink" rel="noreferrer"></a>
                                <a href="https://www.youtube.com/@GGReconGaming" target="_blank" title="youtubeLink" rel="noreferrer"></a>
                                <a href="https://www.tiktok.com/@ggrecon" target="_blank" title="tiktokLink" rel="noreferrer"></a>
                            </div>
                        </div>
                    </div>
                    <div className="searchScroll" id="searchBody" />
                </div>
            </div>
            <div className="topNav">
                <div className="container" style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={toggleMenu} className="menuBtn" title="menubutton">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </a>
                    <div className="logo">
                        <a href="https://www.ggrecon.com/"><img src="https://www.ggrecon.com/images/logo.svg" loading="lazy" alt="GGRecon Logo" /></a>
                    </div>
                    <div className="menuBox">
                        <div className="menuBoxInner">
                            <ul className="sectionsMenu">
                                <li className="menuItem2 ">
                                    <a href="https://www.ggrecon.com/news/">News</a>
                                </li>
                                <li className="menuItem5 ">
                                    <a href="https://www.ggrecon.com/guides/">Guides</a>
                                </li>
                                <li className="menuItem3 ">
                                    <a href="https://www.ggrecon.com/originals/">Originals</a>
                                </li>

                                <li className="menuItem4 ">
                                    <a href="https://www.ggrecon.com/reviews/">Reviews</a>
                                </li>

                                <li className="menuItem4 ">
                                    <a href="https://www.ggrecon.com/lists/">Lists</a>
                                </li>

                                <li className="menuItem4 is-active">
                                    <a href="/">Guess the Game</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hLinks" style={{ zIndex: 100 }}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
                        <a onClick={toggleTheme} className="hDark"></a>
                    </div>
                    <ThemeToggle />
                </div>
            </div>
        </nav>
    )
};