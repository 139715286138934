import { useContext, useState } from "react";
import { Info } from "../../icons/Info";
import { Modal } from "../modal/Modal";
import { ModalContent } from "./components/ModalContent";
import { GameContext } from "../../contexts/GameContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import { Theme } from "../../types/Theme";

export const PlatformHelper = () => {
    const { theme } = useContext(ThemeContext); 
    const { platformMap } = useContext(GameContext);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleInfoClick = () => {
        setIsModalOpen(true);
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
    }
    
    const handleLeaveFeedback = () => {
        window.location.href = 'https://www.ggrecon.com/contact-us/'
    }

    return (
        <>
            <div
                className="flex justify-between items-center bg-green-200 rounded p-2 dark:bg-green-900 cursor-pointer"
                onClick={handleInfoClick}

            >
                <div className="font-inter font-bold text-xs text-green-800 dark:text-green-200 mb-[-4px]">
                    How do the platform indicators work?
                </div>
                <div>
                    <Info color={theme === Theme.Light ? '#007864' : '#d0ffff'} size={16} />
                </div>
            </div>
            {isModalOpen && (
                <Modal
                    onClose={handleModalClose}
                    title={<div className="font-square text-base text-white">HOW DO PLATFORM INDICATORS WORK?</div>}
                    action={{ label: 'Leave Feedback', onClick: handleLeaveFeedback }}
                >
                    <ModalContent platformMap={platformMap} />
                </Modal>
            )}
        </>
    )
};
