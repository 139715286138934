import { FirebaseApp } from "firebase/app";
import { DocumentReference, doc, getDoc, getFirestore } from "firebase/firestore";
import { getDate } from "./getDate";
import { Game } from "../types/Game";

export const getTodaysAnswer = async (app: FirebaseApp, games: Game[], id?: string) => {
    const db = getFirestore(app);
    const docRef = doc(db, 'solutions', id || getDate());
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    if (!data) return null;

    return games.find(game => game.id === (data as { solution: DocumentReference }).solution.id);
}
