import { useContext, useMemo } from "react";
import { PlatformMap } from "../../../types/PlatformMap";
import { isSameBrand } from "../helpers/isSameBrand";
import { GuessStatus } from "../types/GuessStatus";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { Theme } from "../../../types/Theme";

interface Props {
    guess: string[]
    platform: string
    actual: string[]
    platformMap: PlatformMap
    guessStatus: GuessStatus
    bgOverride?: string | null
}

export const Platform = ({ guess, platform, actual, platformMap, guessStatus, bgOverride = null }: Props) => {
    const { theme } = useContext(ThemeContext);

    const status = useMemo(() => {
        if (actual.includes(platform)) return GuessStatus.Correct;
        const remaining = actual.filter((actualPlatform) => !guess.includes(actualPlatform));
        if (isSameBrand(platform, remaining, platformMap)) return GuessStatus.Close;
        return GuessStatus.NotClose;
    }, [platform, actual, platformMap, guess]);

    const image = useMemo(() => {
        const platformDetails = platformMap[platform];
        if (theme === Theme.Light) {
            switch (status) {
                case GuessStatus.Correct:
                case GuessStatus.Close:
                    return platformDetails?.lightImage;
                case GuessStatus.NotClose:
                default:
                    return platformDetails?.darkImage;
            }
        }

        switch (status) {
            case GuessStatus.Correct:
                return platformDetails?.darkImage;
            case GuessStatus.Close:
            case GuessStatus.NotClose:
            default:
                return platformDetails?.lightImage;
        }

    }, [theme, platform, status, platformMap]);

    const backgroundClass = useMemo(() => {
        if (bgOverride) return bgOverride;

        switch (status) {
            case GuessStatus.Correct:
                return 'bg-green-500 dark:bg-green-300';
            case GuessStatus.Close:
                return 'bg-orange-500';
            case GuessStatus.NotClose:
            default:
                return 'bg-white dark:bg-black';
        }
    }, [bgOverride, status]);

    const borderClasses = useMemo(() => {
        if (guessStatus === GuessStatus.Correct) return 'border-2 border-white border-solid py-0.5';
        return 'py-1.5';
    }, [guessStatus]);

    return (
        <div 
            className={`w-full rounded-md flex h-9 items-center justify-center px-px py-1.5 ${backgroundClass} ${borderClasses}`}
        >
            {image != null ? 
                <img
                    className="w-full h-full object-contain"
                    src={image}
                    alt={platform}
                />
                : <div>{platform}</div>
            }
        </div>
    )
};
