import { useContext, useMemo } from "react";
import { GameContext } from "../../contexts/GameContext";
import { splitPlatforms } from "./helpers/splitPlatforms";
import { isClose } from "./helpers/isClose";
import { Platform } from "./components/Platform";
import { GuessStatus } from "./types/GuessStatus";
import { HelperTooltip } from "../helperTooltip/HelperTooltip";

interface Props {
    guess: string[]
    actual: string[]
    bordered?: boolean
    showHelpers?: boolean
}

export const PlatformDisplay = ({ guess, actual, bordered = false, showHelpers = true }: Props) => {
    const { platformMap } = useContext(GameContext);

    const status = useMemo(() => {
        if (guess.length === actual.length && guess.every(entry => actual.includes(entry))) return GuessStatus.Correct;
        if (isClose(guess, actual, platformMap)) return GuessStatus.Close;
        return GuessStatus.NotClose;
    }, [guess, actual, platformMap]);

    const backgroundClass = useMemo(() => {
        switch(status) {
            case GuessStatus.Correct:
                return 'bg-green-500 dark:bg-green-300';
            default:
                return 'bg-secondary-500 dark:bg-secondary-1000';
        }
    }, [status]);

    const textClass = useMemo(() => {
        switch(status) {
            case GuessStatus.Correct:
                return 'text-white dark:text-black';
            default:
                return 'text-black dark:text-white';
        }
    }, [status]);

    const borderClass = bordered ? 'border-2 border-black border-solid' : '';

    const rows = splitPlatforms(guess);

    return (
        <>
            <div className={`rounded ${backgroundClass} flex flex-col justify-center items-center py-1 px-2 ${borderClass}`}>
                <div className={`font-interbold text-xs ${textClass} mb-1 flex`}>
                    <div className="opacity-50">Platform</div>
                    {showHelpers && (
                        <div className="ml-0.5 mt-px">
                            <HelperTooltip
                                textColor={backgroundClass.replaceAll('bg-', 'text-')}
                                backgroundColor={textClass.replaceAll('text-', 'bg-')}
                                tooltipId="platform-helper"
                                text="The platform(s) that the game is playable on. Most of the time showing multiple platforms. This will go amber if some of the platforms are close or if some of the platforms are correct, click the 'How do platform indicators work' to learn more about the platforms."
                            />
                        </div>
                    )}
                </div>
                {rows.map((row, index) => (
                    <div key={index} className={`grid grid-cols-${row.length} gap-1 w-full mb-1`}>
                        {row.map((platform) => (
                            <Platform
                                key={platform}
                                platform={platform}
                                guess={guess}
                                actual={actual}
                                platformMap={platformMap}
                                guessStatus={status}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </>
    )
};
