import { useCallback, useEffect, useState } from "react";
import { isStorageEvent } from "../helpers/isStorageEvent";

export const useLocalStorage = (key: string): [string, (value: string) => void] => {
    const [storedValue, setStoredValue] = useState(window.localStorage.getItem(key) || '');

    const setValue = useCallback((newValue: string) => {
        window.localStorage.setItem(key, newValue);
        window.dispatchEvent(new StorageEvent('local-storage', { key }))
    }, [key])

    const handleStorageChange = useCallback((event: Event | StorageEvent) => {
        if (!isStorageEvent(event)) return;
        if (event.key !== key) return;
        setStoredValue(window.localStorage.getItem(key) || '');
    }, [key]);

    useEffect(() => {
        window.addEventListener('storage', handleStorageChange);
        window.addEventListener('local-storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
            window.removeEventListener('local-storage', handleStorageChange);
        }
    }, [handleStorageChange]);

    return [storedValue, setValue]
}