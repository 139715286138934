import { DateTime } from "luxon";
import Calendar, { OnArgs, TileArgs } from "react-calendar";

import './historyModalContent.css';
import { useContext, useEffect, useState } from "react";
import { getDatePrefix } from "../helpers/getDatePrefix";
import { FirebaseContext } from "../../../contexts/FirebaseContext";
import { getMonthsSolutions } from "../helpers/getMonthsSolutions";
import { LoadingOverlay } from "../../../components/loadingOverlay/LoadingOverlay";

export const HistoryModalContent = () => {
    const { app } = useContext(FirebaseContext);

    const [currentKeyPrefix, setCurrentKeyPrefix] = useState<string>(getDatePrefix());
    const [solutionDates, setSolutionDates] = useState<string[]>([]);
    const [isLoadingSolutions, setIsLoadingSolutions] = useState(false);

    useEffect(() => {
        if (app == null) return;
        setIsLoadingSolutions(true);
        setSolutionDates([]);
        getMonthsSolutions(app, currentKeyPrefix)
            .then((data) => {
                setSolutionDates(data);
                setIsLoadingSolutions(false);
            })
    }, [app, currentKeyPrefix]);

    const handleDayClick = (value: Date) => {
        window.location.href = `?date=${DateTime.fromJSDate(value).toISODate()}`
    }

    const handleActiveStartDateChange = ({ activeStartDate }: OnArgs) => {
        if (activeStartDate == null) return;
        setCurrentKeyPrefix(getDatePrefix(activeStartDate));
    }

    const getTileClass = ({ date }: TileArgs) => {
        if (getDatePrefix(date) !== currentKeyPrefix) return 'other-month';
        return '';
    };

    const isTileDisabled = ({ date }: TileArgs) => {
        const tileDate = DateTime.fromJSDate(date).startOf('day');
        const todaysDate = DateTime.now().startOf('day');
        if (tileDate.diff(todaysDate).as('milliseconds') > 0) return true;
        if (getDatePrefix(date) !== currentKeyPrefix) return true;
        return !solutionDates.includes(tileDate.toISODate() || '')
    }

    return (
        <div>
            {isLoadingSolutions && <LoadingOverlay />}
            <Calendar
                onClickDay={handleDayClick}
                onActiveStartDateChange={handleActiveStartDateChange}
                tileClassName={getTileClass}
                tileDisabled={isTileDisabled}
                minDate={new Date('2024-03-02')}
                maxDate={new Date()}
            />
        </div>
    )
}