export const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="footerText">
                    <p>Discover GGRecon</p>
                    <p></p><p>Welcome to a place where words matter. On GGRecon, smart voices &amp; original ideas take centre stage.</p>
                    <p>Be sure to <a rel="noopener noreferrer" href="https://news.google.com/publications/CAAqBwgKMOfElwswnu6uAw" target="_blank">follow us</a> on Google News.</p><p></p>
                </div>
                <div className="footerRight">
                    <div className="footerLinks">
                        <div><a href="https://www.ggrecon.com/authors/">Authors</a></div>
                        <div><a href="https://www.ggrecon.com/cookies-policy/">Cookies Policy</a></div>
                        <div><a href="https://www.ggrecon.com/terms-of-use/">Terms of Use</a></div>
                        <div><a href="https://www.ggrecon.com/privacy-policy/">Privacy Policy</a></div>
                        <div><a href="https://www.ggrecon.com/policies/">Policies</a></div>
                        <div><a href="https://www.ggrecon.com/in-the-press/">In The Press</a></div>
                        <div><a href="https://www.ggrecon.com/about/">About</a></div>
                        <div><a href="https://www.ggrecon.com/contact-us/">Contact Us</a></div>
                    </div>
                    <div className="clr"></div>
                    <div className="footerSoc">
                        <a href="https://www.facebook.com/GGReconEsports" target="_blank" title="facebookLink" rel="noreferrer"></a>
                        <a href="https://twitter.com/ggrecongaming" target="_blank" title="twitterLink" rel="noreferrer"></a>
                        <a href="https://www.instagram.com/ggrecon_/" target="_blank" title="instagramLink" rel="noreferrer"></a>
                        <a href="https://www.youtube.com/@GGReconGaming" target="_blank" title="youtubeLink" rel="noreferrer"></a>
                        <a href="https://www.tiktok.com/@ggrecon" target="_blank" title="tiktokLink" rel="noreferrer"></a>
                    </div>
                </div>
            </div>
        </footer>
    )
};
