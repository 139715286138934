import { useContext } from "react"
import { ThemeContext } from "../../../contexts/ThemeContext"
import { Theme } from "../../../types/Theme";

export const ThemeToggle = () => {
    const { theme, setTheme } = useContext(ThemeContext);

    const toggleTheme = () => {
        setTheme(theme === Theme.Light ? Theme.Dark : Theme.Light);
    }
    return <div onClick={toggleTheme} className="themeSwitch themeSwitchPosn"><div></div></div>
}