import { useMemo } from "react"
import { GameState } from "../../../types/GameState"

interface Props {
    gameState: GameState
}

export const GuessCount = ({ gameState }: Props) => {
    const bgClass = useMemo(() => {
        if (gameState.guesses.length === 9) return 'dark:bg-red-500';
        if (gameState.guesses.length >= 7) return 'dark:bg-orange-500';
        return 'dark:bg-black'
    }, [gameState]);

    if (gameState.guesses.some((guess) => guess.isCorrect)) {
        return (
            <div className="text-xs font-bold bg-green-500 py-0.5 px-2 text-white rounded uppercase">
                You guessed correctly!
            </div>
        )
    }

    if (gameState.guesses.length === 10) {
        return (
            <div className="text-xs font-bold dark:bg-black dark:py-0.5 dark:px-1 dark:text-white dark:rounded">
                Unlucky, try again tomorrow...
            </div>
        )
    }

    return (
        <div className={`text-xs font-bold ${bgClass} dark:py-0.5 dark:px-1 dark:text-white dark:rounded`}>
            Guess {gameState.guesses.length + 1} of 10
        </div>
    )
}