import './App.css';

import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { Content } from './containers/content/Content';
import { FirebaseProvider } from './contexts/FirebaseContext';
import { GameProvider } from './contexts/GameContext';
import { IndexedDBProvider } from './contexts/IndexedDBContext';
import { ThemeProvider } from './contexts/ThemeContext';

const App = () => {
  return (
    <FirebaseProvider>
      <IndexedDBProvider>
        <ThemeProvider>
          <GameProvider>
              <div className="flex flex-col min-h-screen">
                <Header />
                <main className="flex-1 flex flex-col">
                  <Content/>
                </main>
                <Footer />
              </div>
          </GameProvider>
        </ThemeProvider>
      </IndexedDBProvider>
    </FirebaseProvider>
  );
}

export default App;
