import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Game } from '../types/Game';
import { getGames } from '../helpers/getGames';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { getNewGameState } from '../helpers/getNewGameState';
import { GameState } from '../types/GameState';
import { IndexedDBContext } from './IndexedDBContext';
import { getGameHistory } from '../helpers/getGameHistory';
import { ResultSummary } from '../types/Result';
import { addHistoryEntry } from '../helpers/addHistoryEntry';
import { getDate } from '../helpers/getDate';
import { getStreakCount } from '../helpers/getStreakCount';
import { FirebaseContext } from './FirebaseContext';
import { getTodaysAnswer } from '../helpers/getTodaysAnswer';
import { useSearchParams } from '../hooks/useSearchParams';
import { EMPTY_GAME_STATE } from '../constants/EmptyGameState';
import { DateTime } from 'luxon';
import { getPlatformMap } from '../helpers/getPlatformMap';
import { LoadingOverlay } from '../components/loadingOverlay/LoadingOverlay';
import { PlatformMap } from '../types/PlatformMap';

type Props = {
    children: React.ReactNode
}

type ContextValue = {
  games: Game[]
  gameState: GameState
  addGuess: (game: Game) => void
  history: Record<string, ResultSummary>
  streak: number
  maxStreak: number
  platformMap: PlatformMap
}

export const GameContext = createContext<ContextValue>({
  games: [],
  gameState: {
    date: '',
    guesses: [],
    answer: {
      id: '',
      name: '',
      releaseYear: 0,
      genre: '',
      publisher: '',
      developer: '',
      platform: [],
      image: null,
    },
  },
  history: {},
  streak: 0,
  addGuess: () => null,
  maxStreak: 0,
  platformMap: {}
});

export const GameProvider = ({ children }: Props) => {
  const { app } = useContext(FirebaseContext);

  const { database } = useContext(IndexedDBContext);
  const [games, setGames] = useState<Game[]>([]);
  const [platformMap, setPlatformMap] = useState<PlatformMap>({});
  const [history, setHistory] = useState<Record<string, ResultSummary>>({});
  const date = useSearchParams('date');

  const [todaysAnswer, setTodaysAnswer] = useState<Game | null>(null);
  const [historicAnswer, setHistoricAnswer] = useState<Game | null>(null);
  const [hasLoadedGames, setHasLoadedGames] = useState(false);
  const [hasLoadedPlatforms, setHasLoadedPlatforms] = useState(false);
  const [storedGameState, setStoredGameState] = useLocalStorage('game-state');
  const [storedHistoricGameState, setStoredHistoricGameState] = useLocalStorage('historic-game-state');
  const [storedMaxStreak, setStoredMaxStreak] = useLocalStorage('max-streak');

  useEffect(() => {
    let shouldUpdate = true;
    if (app == null || !hasLoadedGames) return;
    getTodaysAnswer(app, games)
      .then(answer => {
        if (!shouldUpdate) return;
        if (answer == null) throw new Error('No answer today');
        setTodaysAnswer(answer);
      });

    return () => {
      shouldUpdate = false;
    }
  }, [hasLoadedGames, app, games]);

  useEffect(() => {
    let shouldUpdate = true;
    setHistoricAnswer(null);
    if (date == null || date === getDate() || app == null || !hasLoadedGames) return;
    setStoredHistoricGameState('');
    getTodaysAnswer(app, games, date)
      .then(answer => {
        if (!shouldUpdate) return;
        if (answer == null) throw new Error('No answer for this date');
        setHistoricAnswer(answer);
      });

    return () => {
      shouldUpdate = false;
    }
  }, [setStoredHistoricGameState, date, hasLoadedGames, app, games]);

  const gameState = useMemo(() => {
    if (todaysAnswer == null) {
      return EMPTY_GAME_STATE
    }
  
    if (storedGameState === '') {
      return getNewGameState(todaysAnswer);
    }

    const parsedValue = JSON.parse(storedGameState) as GameState;

    if (
      !parsedValue.date ||
      parsedValue.date !== getDate() ||
      parsedValue.answer.id !== todaysAnswer.id
    ) {
      // This will cause a game reset if it's a new date
      setStoredGameState('');
      return getNewGameState(todaysAnswer);
    }

    return parsedValue;
  }, [todaysAnswer, storedGameState, setStoredGameState]);

  const historicGameState = useMemo(() => {
    if (date == null || historicAnswer == null) return null;
    if (DateTime.fromISO(date).diff(DateTime.fromISO(getDate())).milliseconds > 0) return null
    if (storedHistoricGameState === '') {
      return getNewGameState(historicAnswer, date);
    }

    const parsedValue = JSON.parse(storedHistoricGameState) as GameState;
  
    return parsedValue;
  }, [date, historicAnswer, storedHistoricGameState]);

  const gameOptions = useMemo(() => {
    const guessesIds = historicGameState ?
      historicGameState.guesses.map((guess) => guess.game.id) :
      gameState.guesses.map((guess) => guess.game.id);
    return games.filter((game) => !guessesIds.includes(game.id));
  }, [historicGameState, gameState, games]);

  const streak = useMemo(() => {
    return getStreakCount(history);
  }, [history]);

  const loadHistory = useCallback(() => {
    if(database == null) return;
    getGameHistory(database)
      .then((history) => {
        const historyMap = history.reduce((output, { date, ...entry }) => ({
          ...output,
          [date]: entry
        }), {});

        setHistory(historyMap)
      });
  }, [database]);

  const addGuess = useCallback((game: Game) => {
    if (gameState.guesses.length === 10 || gameState.guesses.some(guess => guess.isCorrect)) return;
    const isCorrect = game.id === gameState.answer.id;
    const newGameState: GameState = {
      ...gameState,
      guesses: [...gameState.guesses, {
        isCorrect,
        game,
      }]
    }

    setStoredGameState(JSON.stringify(newGameState));

    if (isCorrect && database) {
      addHistoryEntry(database, {
        date: newGameState.date,
        isCorrect: true,
        guesses: newGameState.guesses.length,
      })
      loadHistory();
    } else if (newGameState.guesses.length >= 10 && database) {
      addHistoryEntry(database, {
        date: newGameState.date,
        isCorrect: false,
      })
      loadHistory();
    }
  }, [database, gameState, setStoredGameState, loadHistory]);

  const addHistoricGuess = useCallback((game: Game) => {
    if (
      !historicGameState ||
      historicGameState.guesses.length >= 10 ||
      historicGameState.guesses.some(guess => guess.isCorrect)
    ) return;
    const isCorrect = game.id === historicGameState.answer.id;
    const newGameState: GameState = {
      ...historicGameState,
      guesses: [...historicGameState.guesses, {
        isCorrect,
        game,
      }]
    }

    setStoredHistoricGameState(JSON.stringify(newGameState));
  }, [historicGameState, setStoredHistoricGameState]);

  useEffect(() => {
    if (app == null) return;
    getGames(app)
        .then(result => {
            setGames(result);
            setHasLoadedGames(true);
        })
  }, [app]);
  
  useEffect(() => {
    if (app == null) return;
    getPlatformMap(app)
        .then(result => {
            setPlatformMap(result);
            setHasLoadedPlatforms(true);
        })
  }, [app]);

  useEffect(() => {
    if ((!storedMaxStreak && streak > 0) || streak > Number(storedMaxStreak)) {
      setStoredMaxStreak(streak.toString());
    }
  }, [streak, storedMaxStreak, setStoredMaxStreak]);

  useEffect(() => {
    loadHistory();
  }, [loadHistory]);

  return (
    <GameContext.Provider
      value={{
        games: gameOptions,
        gameState: historicGameState ? historicGameState : gameState,
        addGuess: historicGameState ? addHistoricGuess : addGuess,
        history,
        streak,
        maxStreak: storedMaxStreak ? Number(storedMaxStreak) : 0,
        platformMap,
      }}
    >
      {hasLoadedGames && hasLoadedPlatforms ? children : <LoadingOverlay />}
    </GameContext.Provider>
  )
}