import { useContext, useState } from "react";
import { Modal } from "../../components/modal/Modal";
import { GuessResult } from "./components/GuessResult";
import { StreakText } from "./components/StreakText";
import { GameContext } from "../../contexts/GameContext";
import { GuessList } from "../../components/guessList/GuessList";
import { Countdown } from "../../components/countdown/Countdown";
import { ShareButton } from "../../components/shareButton/ShareButton";
import { getDate } from "../../helpers/getDate";
import { IconButton } from "../../components/iconButton/IconButton";
import { Calendar } from "../../icons/Calendar";

interface Props {
    onCalendarClick: () => void
}

export const WinScreen = ({ onCalendarClick }: Props) => {
    const { gameState, streak } = useContext(GameContext);

    const [showModal, setShowModal] = useState(true);

    const handleCloseClick = () => {
        setShowModal(false);
    }

    const showCountdown = gameState.date === getDate();

    return (
        <>
            {showCountdown && <div className="font-square text-xs uppercase text-secondary-700 mb-6">Next game begins in: <Countdown /></div>}
            <GuessList guesses={gameState.guesses} actual={gameState.answer} />
            {showModal && <Modal title={<StreakText streakCount={streak} />} onClose={handleCloseClick}>
                <div className="rounded overflow-hidden shadow-2md">
                    <GuessResult guessCount={gameState.guesses.length} />
                    <div className="p-2 bg-white dark:bg-black">
                        {gameState.answer.image && (
                            <img className="w-full rounded overflow-hidden mb-2" src={gameState.answer.image} alt={gameState.answer.name} />
                        )}
                        <div>
                            <div className="font-inter text-black dark:text-white text-xs text-center mb-2 font-bold">Today's game is:</div>
                            <div className="font-square text-black dark:text-white text-center text-xl">{gameState.answer.name}</div>
                            <div className="flex flex-col items-center mt-3.5 mb-2.5">
                                <span>
                                    <ShareButton guessCount={gameState.guesses.length}/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {showCountdown && <div className="font-square text-center text-white text-xs mt-6 mb-3">NEXT GAME BEGINS IN: <Countdown /></div>}
                <div className="mt-3">
                    <span className="font-square text-center text-white text-xs"> Feeling confident? Click the calendar to play previous days </span>
                    <span>
                        <IconButton onClick={onCalendarClick}>
                            <Calendar color="white" size={18} />
                        </IconButton>
                    </span>
                </div>
            </Modal>}
        </>
    )
};
