import { DeveloperDisplay } from "../../../components/developerDisplay/DeveloperDisplay";
import { GenreDisplay } from "../../../components/genreDisplay/GenreDisplay";
import { PlatformDisplay } from "../../../components/platformDisplay/PlatformDisplay";
import { PublisherDisplay } from "../../../components/publisherDisplay/PublisherDisplay";
import { YearDisplay } from "../../../components/yearDisplay/YearDisplay";
import { Game } from "../../../types/Game";

interface Props {
    actual: Game
}

export const CorrectAnswer = ({ actual }: Props) => {
    return (
        <div className="w-screen max-w-[480px] flex flex-col items-center justify-center">
            <div className="w-full font-square uppercase text-xl text-white dark:bg-green-500 bg-green-700 text-center p-3">Today's game was...</div>
            <div className="w-full dark:bg-green-300 bg-green-500 flex items-center justify-center">
                <div className="w-full max-w-[336px] py-3">
                    <div className="flex items-center gap-2 mb-2">
                        <div className="rounded-full overflow-hidden bg-secondary-500 h-12 w-12 max-w-12 min-w-12">
                            {actual.image && <img className="object-cover w-full h-full" src={actual.image} alt={actual.name} />}
                        </div>
                        <div className="font-square uppercase text-sm text-black flex-1">{actual.name}</div>
                    </div>
                    <div className="grid grid-cols-3 gap-0.5">
                        <div>
                            <YearDisplay year={actual.releaseYear} targetYear={actual.releaseYear} bordered />
                        </div>
                        <div className="col-span-2">
                            <GenreDisplay guess={actual.genre} actual={actual.genre} bordered />
                        </div>
                        <div className="col-span-3">
                            <PublisherDisplay guess={actual.publisher} actual={actual.publisher} bordered />
                        </div>
                        <div className="col-span-3">
                            <DeveloperDisplay guess={actual.developer} actual={actual.developer} bordered />
                        </div>
                        <div className="col-span-3">
                            <PlatformDisplay guess={actual.platform} actual={actual.platform} bordered />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
