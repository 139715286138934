interface Props {
    color?: string
}

export const DownChevron = ({ color = '#000' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.505 9.005c.39.39.39 1.02 0 1.41l-4.59 4.59a.996.996 0 0 1-1.41 0l-4.59-4.59a.996.996 0 1 1 1.41-1.41l3.89 3.88 3.88-3.88c.39-.39 1.03-.38 1.41 0z"
            fill={color}
        />
    </svg>
);
