import { useEffect, useState } from "react";

export const useSearchParams = (property: string) => {
    const [value, setValue] = useState<string | null>(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const value = params.get(property);
    
        setValue(value);
    }, [property]);

    return value;
};
