interface Props {
    color?: string
    size?: number
}

export const Calendar = ({ color = '#69B3E7', size = 23 }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 23 23" fill="none">
        <path d="M5.75.958a.958.958 0 1 1 1.917 0v1.917a.958.958 0 0 1-1.917 0V.958zM15.333.958a.958.958 0 0 1 1.917 0v1.917a.958.958 0 0 1-1.917 0V.958z" fill={color} />
        <path d="M4.792 1.917H1.917A1.917 1.917 0 0 0 0 3.833V5.75h23V3.833a1.917 1.917 0 0 0-1.917-1.916h-2.875v.958a1.917 1.917 0 1 1-3.833 0v-.958h-5.75v.958a1.917 1.917 0 1 1-3.833 0v-.958z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 6.708h23v14.375A1.917 1.917 0 0 1 21.083 23H1.917A1.917 1.917 0 0 1 0 21.083V6.708zm7.667 2.875a.958.958 0 1 0 0 1.917.958.958 0 0 0 0-1.917zm3.833 0a.958.958 0 1 0 0 1.917.958.958 0 0 0 0-1.917zm2.875.959a.958.958 0 1 1 1.917 0 .958.958 0 0 1-1.917 0zm4.792-.959a.958.958 0 1 0 0 1.917.958.958 0 0 0 0-1.917zM2.875 14.375a.958.958 0 1 1 1.917 0 .958.958 0 0 1-1.917 0zm3.833 0a.958.958 0 1 1 1.917 0 .958.958 0 0 1-1.917 0zm4.792-.958a.958.958 0 1 0 0 1.916.958.958 0 0 0 0-1.916zm2.875.958a.958.958 0 1 1 1.917 0 .958.958 0 0 1-1.917 0zm4.792-.958a.958.958 0 1 0 0 1.916.958.958 0 0 0 0-1.916zM2.875 18.208a.958.958 0 1 1 1.917 0 .958.958 0 0 1-1.917 0zm3.833 0a.958.958 0 1 1 1.917 0 .958.958 0 0 1-1.917 0zm4.792-.958a.958.958 0 1 0 0 1.917.958.958 0 0 0 0-1.917zm2.875.958a.958.958 0 1 1 1.917 0 .958.958 0 0 1-1.917 0z" fill={color}/>
    </svg>
)