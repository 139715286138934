import { FirebaseApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { PlatformMap } from "../types/PlatformMap";
import storagePathToUrl from "./storagePathToUrl";

export const getPlatformMap= async (app: FirebaseApp): Promise<PlatformMap> => {
    const db = getFirestore(app);
    const querySnapshot = await getDocs(collection(db, 'platforms'));

    const platformMap: PlatformMap = {};
    querySnapshot.forEach((doc) => {
        const data = doc.data() as { brand: string, name: string, lightImage: string | null, darkImage: string | null };
        platformMap[data.name] = {
            brand: data.brand,
            lightImage: data.lightImage,
            darkImage: data.darkImage,
        }
    });

    await Promise.all(Object.entries(platformMap).map(async ([key, value]) => {
        platformMap[key] = {
            brand: value.brand,
            lightImage: value.lightImage && value.lightImage !== '' ? await storagePathToUrl(value.lightImage) : null,
            darkImage: value.darkImage && value.darkImage !== '' ? await storagePathToUrl(value.darkImage) : null,
        } 
    }));

    return platformMap;
}