import { useMemo } from "react";

interface Props {
    guessCount: number
}

export const GuessResult = ({ guessCount }: Props) => {
    const backgroundClasses = useMemo(() => {
        switch (guessCount) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                return 'bg-green-500 text-white'
            case 6:
            case 7:
                return 'bg-secondary-700 text-white';
            case 8:
            case 9:
                return 'bg-yellow-500 text-black';
            default:
                return 'bg-orange-500 text-white';

        }
    }, [guessCount]);

    const text = useMemo(() => {
        switch (guessCount) {
            case 1:
                return 'You got it on your first guess - ARE YOU EVEN HUMAN?';
            case 2:
                return 'You got it on your second guess - Unbelievable effort!';
            case 3:
                return 'You got it on your third guess - Great work!';
            case 4:
                return 'You got it on your fourth guess - Well done!';
            case 5:
                return 'You got it on your fifth guess - Impressive!';
            case 6:
                return 'You got it on your sixth guess - High five!';
            case 7:
                return 'You got it on your seventh guess - Good stuff!';
            case 8:
                return 'You got it on your eighth guess - GGs!';
            case 9:
                return 'You got it on your ninth guess - Decent effort!';
            default:
                return 'You got it on your last guess - Close one!';

        }
    }, [guessCount]);

    return (
        <div className={`${backgroundClasses} text-center font-opensans text-xs font-bold p-2`}>
            {text}
        </div>
    )
};