import { PlatformMap } from "../../../types/PlatformMap";
import { Platform } from "../../platformDisplay/components/Platform";
import { GuessStatus } from "../../platformDisplay/types/GuessStatus";

import lastofus from '../../../assets/images/lastofus.png';
import donkeykong from '../../../assets/images/donkeykong.png';
import alanwake from '../../../assets/images/alanwake.png';
import { GuessDisplay } from "../../guessDisplay/GuessDisplay";
import { Game } from "../../../types/Game";

interface Props {
    platformMap: PlatformMap
}

const answer: Game = {
    id: '1230',
    name: 'Baldur\'s Gate 3',
    image: lastofus,
    releaseYear: 2023,
    genre: 'RPG',
    publisher: 'Larian Studios',
    developer: 'Larian Studios',
    platform: ['PC', 'PlayStation 5', 'Xbox One', 'Xbox Series X|S'],
}

const example2: Game = {
    id: '1231',
    name: 'The Last of Us',
    image: lastofus,
    releaseYear: 2013,
    genre: 'Action Adventure',
    publisher: 'Sony',
    developer: 'Naughty Dog',
    platform: ['PlayStation 3', 'PlayStation 4'],
}

const example1: Game = {
    id: '1232',
    name: 'Donkey Kong Country: Tropical Freeze',
    image: donkeykong,
    releaseYear: 2014,
    genre: 'Platformer',
    publisher: 'Nintendo',
    developer: 'Retro Studios',
    platform: ['Nintendo Wii | Wii U', 'Nintendo Switch'],
}

const example3: Game = {
    id: '1233',
    name: 'Alan Wake 2',
    image: alanwake,
    releaseYear: 2023,
    genre: 'Horror',
    publisher: 'Epic Games',
    developer: 'Remedy Entertainment',
    platform: ['PlayStation 5', 'Xbox Series X|S', 'PC'],
}

export const ModalContent = ({ platformMap }: Props) => {
    return (
        <div>
            <div className="bg-white dark:bg-black dark:text-white rounded p-3 mb-6">
                <div className="font-square text-sm mb-3">What is a Platform Indicator?</div>
                <div className="mb-4 text-base font-opensans">
                    A colour coded background to a platform that indicates whether the platform shown is close to the answer.
                </div>
                <div className="grid grid-cols-3 gap-1">
                    <div className="flex flex-col items-center">
                        <div className="font-square text-xs mb-1">WRONG</div>
                        <Platform platform="Xbox" actual={["PlayStation 4"]} platformMap={platformMap} guess={[]} guessStatus={GuessStatus.NotClose} bgOverride="bg-white dark:bg-secondary-1000"/>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="font-square text-xs mb-1">CLOSE</div>
                        <Platform platform="PlayStation" actual={["PlayStation 4"]} platformMap={platformMap} guess={[]} guessStatus={GuessStatus.NotClose} />
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="font-square text-xs mb-1">CORRECT</div>
                        <Platform platform="PlayStation 4" actual={["PlayStation 4"]} platformMap={platformMap} guess={[]} guessStatus={GuessStatus.NotClose} />
                    </div>
                </div>
            </div>
            <div className="text-xs font-inter font-bold text-white mb-2">Main Things to Remember:</div>
            <div className="bg-white dark:bg-black dark:text-white rounded p-3 mb-2">
                <div className="font-inter font-bold text-xs mb-2">
                    The answer may contain more platforms than what’s shown on your guesses platforms.
                </div>
                <div className="font-openssans text-xs">
                    For example, in a scenario where the answer for that day is on the PS5 and Xbox Series X/S, the game you guess might be just on the PS5 and not on any Xbox platform, it will show as correct to being on PS5, but no indication that the answer is on an Xbox platform, as your guess wasn't on any.
                </div>
            </div>
            <div className="bg-white dark:bg-black dark:text-white rounded p-3 mb-6">
                <div className="font-inter font-bold text-xs mb-2">
                    When a platform deems something as ‘Close’ means that the answer isn’t on that specific platform, but it is on another model of that platform.
                </div>
                <div className="font-openssans text-xs">
                    For example, the answer may be on the PS5, but the guess you make may be on the PS1, which it will show as ‘Close’ as the platform is on another PlayStation.
                </div>
            </div>
            <div className="text-xs font-inter font-bold text-white mb-2">Examples</div>
            <div className="bg-white dark:bg-black dark:text-white rounded p-3 mb-2">
                <div className="font-square text-sm text-secondary-700 mb-3">
                    Not On These Platform(s):
                </div>
                <div>
                    <GuessDisplay guess={example1} actual={answer} />
                </div>
            </div>
            <div className="bg-white dark:bg-black dark:text-white rounded p-3 mb-2">
                <div className="font-square text-sm text-secondary-700 mb-3">
                    Platform(s) Are <span className="text-orange-500">Close</span>:
                </div>
                <div>
                    <GuessDisplay guess={example2} actual={answer} />
                </div>
            </div>
            <div className="bg-white dark:bg-black dark:text-white rounded p-3 mb-2">
                <div className="font-square text-sm text-secondary-700 mb-3">
                    Platform(s) Are <span className="text-green-500">Correct</span>:
                </div>
                <div>
                    <GuessDisplay guess={example3} actual={answer} />
                </div>
            </div>
        </div>
    )
};
