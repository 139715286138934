import { createContext, useEffect, useState } from 'react'
import { FirebaseApp, initializeApp } from 'firebase/app';

type Props = {children: React.ReactNode}

export const FirebaseContext = createContext<
  { app: null | FirebaseApp }
>({
  app: null,
});

export const FirebaseProvider = ({ children }: Props) => {
  const [app, setApp] = useState<null | FirebaseApp>(null);

  useEffect(() => {
    const firebaseConfig = {
        apiKey: "AIzaSyDtyLk01XffkiwtS6KdpORidl32-Jpqfew",
        authDomain: "guess-the-game-fee9c.firebaseapp.com",
        projectId: "guess-the-game-fee9c",
        storageBucket: "guess-the-game-fee9c.appspot.com",
        messagingSenderId: "94392582812",
        appId: "1:94392582812:web:ac12971e575fc85148e359",
        measurementId: "G-H4WEELGWHG"
      };

      setApp(initializeApp(firebaseConfig));
  }, []);

  return (
    <FirebaseContext.Provider value={{ app }}>
      {app ? children : null}
    </FirebaseContext.Provider>
  )
}