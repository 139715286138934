import { DateTime } from "luxon";
import { Result } from "../types/Result";
import { getDate } from "./getDate";

export const getStreakCount = (history: Record<string, Omit<Result, 'date'>>): number => {
    const dateString = getDate();

    const previousDate = DateTime.fromISO(dateString).minus({ days: 1 }).toISODate();

    if (previousDate == null) throw new Error('Invalid date');

    const todaysResult = history[dateString];
    if (!todaysResult) return getPreviousStreak(history, previousDate);
    if (!todaysResult.isCorrect) return 0;

    return 1 + getPreviousStreak(history, previousDate);
};

const getPreviousStreak = (history: Record<string, Omit<Result, 'date'>>, dateString: string): number => {
    const result = history[dateString];
    if (!result) return 0;
    if (!result.isCorrect) return 0;

    const previousDate = DateTime.fromISO(dateString).minus({ days: 1 }).toISODate();

    if (previousDate == null) throw new Error('Invalid date');

    return 1 + getPreviousStreak(history, previousDate);
};
