import { createContext, useEffect, useState } from 'react';

type Props = {children: React.ReactNode}

export const IndexedDBContext = createContext<
  { database: IDBDatabase | null }
>({
  database: null,
});

export const IndexedDBProvider = ({ children }: Props) => {
  const [database, setDatabase] = useState<IDBDatabase | null>(null);

  useEffect(() => {
    const openRequest = indexedDB.open('guess-the-game', 1);

    openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = openRequest.result;
      switch(event.oldVersion) {
        case 0:
          db.createObjectStore('history', { keyPath: 'date' })
      }
    };
    
    openRequest.onerror = function() {
      console.error("Error", openRequest.error);
    };
    
    openRequest.onsuccess = function() {
      setDatabase(openRequest.result)
    };
  }, []);

  return (
    <IndexedDBContext.Provider value={{ database }}>
      {children}
    </IndexedDBContext.Provider>
  )
}