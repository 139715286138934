import { PlatformMap } from "../../../types/PlatformMap";

export const isClose = (guess: string[], actual: string[], platformMap: PlatformMap) => {
    return guess.some((guessPlatform) => actual.some((actualPlatform) => {
        const guessBrand = platformMap[guessPlatform]?.brand;

        if (!guessBrand) return false;
    
        const actualBrand = platformMap[actualPlatform]?.brand;
    
        if (!actualBrand) return false;
    
        return guessBrand === actualBrand;
    }));
};
