import { createContext, useState } from 'react'
import { Theme } from '../types/Theme'
import { getThemeCookie } from '../helpers/getThemeCookie'
import { setThemeCookie } from '../helpers/setThemeCookie';

type Props = {children: React.ReactNode}

export const ThemeContext = createContext<
  {theme: Theme; setTheme: (theme: Theme) => void}
>({
  theme: Theme.Light,
  setTheme: () => null,
});

export const ThemeProvider = ({ children }: Props) => {
  const [theme, setTheme] = useState(getThemeCookie())

  const updateTheme = (newTheme: Theme) => {
    setThemeCookie(newTheme);
    setTheme(newTheme);
  }
  return (
    <ThemeContext.Provider value={{ theme, setTheme: updateTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}