import { Theme } from "../types/Theme";

export const getThemeCookie = (): Theme => {
    const isBrowser = typeof window !== 'undefined';
    return (
        (isBrowser &&
          document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=');
            return parts[0] === 'theme' ? decodeURIComponent(parts[1]) : r;
          }, '') as Theme) || Theme.Light
      );
}