import { useMemo, useState } from "react";
import { Share } from "../../icons/Share";
import { ShareModal } from "./components/ShareModal";
import { isWindows } from "../../helpers/isWindows";

interface Props {
    guessCount?: number
}

export const ShareButton = ({ guessCount }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const text = useMemo(() => {
        if (!guessCount) return `I tried GGRecon's Guess The Game but couldn't solve it. Try it yourself`;
        if (guessCount === 1) return `I did GGRecon's Guess The Game in 1 attempt. Try it yourself`;
        return `I did GGRecon's Guess The Game in ${guessCount} attempts. Try it yourself`;
    }, [guessCount]);

    const url = `https://www.guessthegame.ggrecon.com/`;

    const handleButtonClick = () => {
        if (navigator.share && !isWindows()) {
            navigator.share({
                text: `${text} - ${url}`,
            })
            .catch((e) => {
                // TODO - better handling of errors from this
                // We need the catch because it errors if the user cancels 
            });
        } else {
            setIsModalOpen(true);
        }
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
    }

    return (
        <>
            <button
                className="bg-blue-300 text-white font-interbold flex items-center justify-center text-xs p-2.5 rounded-md"
                onClick={handleButtonClick}
            >
                <Share />
                <div className="ml-2 mb-[-5px]">SHARE</div>
            </button>
            {isModalOpen && <ShareModal onClose={handleModalClose} message={text} url={url} />}
        </>
    );
};
