import { FirebaseApp } from "firebase/app";
import { doc, getDoc, getFirestore } from "firebase/firestore";

const checkIfDateExists = async (app: FirebaseApp, id: string) => {
    const db = getFirestore(app);
    const docRef = doc(db, 'solutions', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.id;
    }

    return null;
}

export const getMonthsSolutions = async (
    app: FirebaseApp,
    datePrefix: string
): Promise<string[]> => {
    const promises = [];
    for (let i = 1; i <= 31; i++) {
        const numberString = i <= 9 ? "0" + i : i.toString();
        promises.push(checkIfDateExists(app, datePrefix + '-' + numberString));
    }

    const data = await Promise.all(promises);

    const ids: string[] = [];

    data.forEach(entry => {
        if (entry != null) {
            ids.push(entry);
        }
    });

    return ids;
}
