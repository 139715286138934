import { GameState } from "../types/GameState";
import { Game } from "../types/Game";
import { getDate } from "./getDate";

export const getNewGameState = (answer: Game, date?: string | null): GameState => {
    return {
        date: date || getDate(),
        answer: answer,
        guesses: [],
    }
};
