import { useContext, useMemo } from "react";
import { UpChevron } from "../../icons/UpChevron";
import { DownChevron } from "../../icons/DownChevron";
import { ThemeContext } from "../../contexts/ThemeContext";
import { Theme } from "../../types/Theme";
import { HelperTooltip } from "../helperTooltip/HelperTooltip";

interface Props {
    year: number
    targetYear: number
    bordered?: boolean
    showHelpers?: boolean
}

enum Status {
    NotClose,
    Close,
    Correct,
}

export const YearDisplay = ({ year, targetYear, bordered = false, showHelpers = true }: Props) => {
    const { theme } = useContext(ThemeContext);

    const status = useMemo(() => {
        if (year === targetYear) return Status.Correct;
        if (Math.abs(targetYear - year) <= 2) return Status.Close;
        return Status.NotClose;
    }, [targetYear, year]);

    const backgroundClasses = useMemo(() => {
        switch(status) {
            case Status.Close:
                return 'bg-orange-500';
            case Status.Correct:
                return 'bg-green-500 dark:bg-green-300';
            default:
                return 'bg-secondary-500 dark:bg-secondary-1000';
        }
    }, [status]);


    const textClasses = useMemo(() => {
        switch(status) {
            case Status.Close:
                return 'text-white';
            case Status.Correct:
                return 'text-white dark:text-black';
            default:
                return 'text-black dark:text-white';
        }
    }, [status]);

    const chevron = useMemo(() => {
        const color = theme === Theme.Dark || status === Status.Close ? '#fff' : '#000'
        if (targetYear > year) return <UpChevron color={color} />;
        if (targetYear < year) return <DownChevron color={color} />;
        return null;
    }, [theme, status, year, targetYear]);

    const borderClass = bordered ? 'border-2 border-black border-solid' : '';


    return (
        <div className={`rounded ${backgroundClasses} flex flex-col justify-center items-center py-1 ${borderClass}`}>
            <div className={`font-interbold text-xs ${textClasses} flex`}>
                <div className="opacity-50">Release Year</div>
                {showHelpers && (
                    <div className="ml-0.5 mt-[1px]">
                        <HelperTooltip
                            textColor={backgroundClasses.replaceAll('bg-', 'text-')}
                            backgroundColor={textClasses.replaceAll('text-', 'bg-')}
                            tooltipId="release-year-helper"
                            text="The year the game was released, not when it was re-released. An amber indicator will appear if your guess is within 2 years of the answer’s released year."
                            position="right"
                        />
                    </div>
                )}
            </div>
            <div className={`font-interbold text-lg ${textClasses} flex items-center`}>
                <div>{year}</div>
                {chevron}
            </div>
        </div>
    )
};
