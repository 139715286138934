import { GuessList } from "../../components/guessList/GuessList";
import { Game } from "../../types/Game";
import { Guess } from "../../types/Guess";

interface Props {
    actual: Game
    guesses: Guess[]
}

export const Guesses = ({ actual, guesses }: Props) => {
    return (
        <GuessList actual={actual} guesses={guesses} />
    )
};
