import { useMemo } from "react";
import { HelperTooltip } from "../helperTooltip/HelperTooltip";

interface Props {
    guess: string
    actual: string
    bordered?: boolean
    showHelpers?: boolean
}

enum Status {
    Incorrect,
    Correct,
}

export const GenreDisplay = ({ guess, actual, bordered = false, showHelpers = true }: Props) => {
    const status = useMemo(() => {
        if (guess === actual) return Status.Correct;
        return Status.Incorrect;
    }, [guess, actual]);

    const backgroundClass = useMemo(() => {
        switch(status) {
            case Status.Correct:
                return 'bg-green-500 dark:bg-green-300';
            default:
                return 'bg-secondary-500 dark:bg-secondary-1000';
        }
    }, [status]);


    const textClass = useMemo(() => status === Status.Incorrect ? 'text-black dark:text-white' : 'text-white dark:text-black', [status]);
    const borderClass = bordered ? 'border-2 border-black border-solid' : '';

    return (
        <div className={`rounded ${backgroundClass} flex flex-col justify-center items-center py-1 ${borderClass}`}>
            <div className={`font-interbold text-xs ${textClass} flex`}>
                <div className="opacity-50">Genre</div>
                {showHelpers && (
                    <div className="ml-0.5 mt-px">
                        <HelperTooltip
                            textColor={backgroundClass.replaceAll('bg-', 'text-')}
                            backgroundColor={textClass.replaceAll('text-', 'bg-')}
                            tooltipId="genre-helper"
                            text="The specific genre of the game, such as FPS (First person shooter) or RPG (Role playing game)."
                            position="left"
                        />
                    </div>
                )}

            </div>
            <div className={`font-interbold text-lg ${textClass} flex items-center`}>
                {guess}
            </div>
        </div>
    )
};
