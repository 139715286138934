import { DateTime } from "luxon";
import { useEffect, useState } from "react";

export const Countdown = () => {
    const getTimeText = () => {
        const end = DateTime.now().endOf('day');
        const now = DateTime.now();
        const remaining = end.diff(now)

        return remaining.toFormat(`hh:mm:ss`)
    }

    const [remaining, setRemaining] = useState(getTimeText());

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(getTimeText());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return remaining;
};
