import { Result } from "../types/Result";

export const getGameHistory = async (database: IDBDatabase): Promise<Result[]> => new Promise((resolve, reject) => {
    const request = database.transaction('history', 'readonly').objectStore('history').getAll();

    request.onsuccess = ()=> {
        const history = request.result;

        resolve(history as Result[]);
    }

    request.onerror = (err)=> {
        reject(`Error getting history: ${err}`)
    }
});
