import { Result } from "../types/Result";

export const addHistoryEntry = async (database: IDBDatabase, result: Result) => new Promise((resolve, reject) => {
    const request = database.transaction('history', 'readwrite').objectStore('history').add(result);

    request.onsuccess = ()=> {
        resolve(null);
    }

    request.onerror = (err)=> {
        reject(`Error adding history: ${err}`)
    }
});